.transaction-main-div {
  .primary {
    background: var(--chatlook-darkblue--) !important;
    border-color: transparent !important;
  }

  .merchant-management-card {
    .form-check-input:checked {
      background-color: var(--chatlook-darkblue--) !important;
      border-color: var(--chatlook-darkblue--) !important;
      box-shadow: unset !important;
    }
    .form-check-input:focus {
      border-color: lightgray !important;
      box-shadow: unset !important;
    }
    .row {
      display: flex;
      .card {
        margin-bottom: 10px !important ;
        .card-body {
          border-radius: 8px;
          background-color: white;
          box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
        }
      }
    }
  }
  @media (max-width: 400px) {
    .front-filter-div {
      flex-wrap: wrap;
      select {
        max-width: 196px;
      }
      input {
        margin-top: 10px;
      }
    }
  }
}
.text-danger-glow {
  color: #ff4141;
  text-shadow: 0 0 20px #f00, 0 0 30px #f00, 0 0 40px #f00, 0 0 50px #f00,
    0 0 60px #f00, 0 0 70px #f00, 0 0 80px #f00;
}

.blink {
  animation: blinker 1s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
}
