.report-main-div {
  .primary {
    background: var(--chatlook-darkblue--) !important;
    border-color: transparent !important;
  }

  @media (max-width: 400px) {
    .front-filter-div {
      flex-wrap: wrap;
      select {
        max-width: 196px;
      }
      input {
        margin-top: 10px;
      }
    }
  }
}

.progress-position {
  position: absolute;
  top: 0;
  left: 52%;
  transform: translate(-50%, 0%);
}
.progress-position span {
  color: #414141;
}
.MuiLinearProgress-root.MuiLinearProgress-colorPrimary.MuiLinearProgress-determinate.css-blivg-MuiLinearProgress-root {
  border: 1px solid #41414133 !important;
}
