.proof-sec button {
  border-radius: 6px;
  background: #6933d3;
  border: 0;
  padding: 8px 25px;
  border-radius: 6px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
}
.proof-sec label.label input[type="file"] {
  position: absolute;
  top: -1000px;
}
.proof-sec .label {
  border-radius: 8px;
  border: 1px dashed #8c99a4;
  cursor: pointer;
  padding: 16px 15px;
  display: block;
  text-align: center;
}
.proof-sec .label p {
  color: #9e9e9e;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
}
.proof-sec .label:hover {
  background: rgba(128, 128, 128, 0.349);
}
.proof-sec .label:active {
  background: rgba(128, 128, 128, 0.349);
}
