.toggle-switch {
  border-radius: 12px;
  background: rgb(246, 246, 247);
  width: 105px;
  display: flex;
  border-radius: 7px;
  user-select: none;
  opacity: 1;
  cursor: pointer;
  position: relative;
  padding: 3px;

  border: 1px solid #e1e1e1;
  .option {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 16px;
    color: rgba(47, 43, 61, 0.78);
    border-radius: 6px;
  }
  .rightposi {
    position: absolute;
    right: 18px;
    top: 50%;
    transform: translate(0, -50%);
  }
  .leftposi {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translate(0, -50%);
  }

  .green {
    color: #fff !important;
    background-color: rgb(132 193 159) !important;
  }

  .red {
    color: #fff !important;
    background-color: rgb(253 182 187) !important;
  }
}
.switchmain {
  width: 100px;
  border-radius: 6px;
}
