.view-send-data-modal {
  .primary {
    background: var(--chatlook-darkblue--) !important;
    border-color: transparent !important;
  }
  .webevent-sec {
    background-color: rgb(83 123 255 / 16%);
    // background-color: #80808029;
    padding: 20px;
    border-radius: 10px;
  }
  .webevent-sec pre {
    margin-bottom: 0;
    background-color: transparent;
    font-size: 14px;
    font-weight: 500;
    color: rgba(47, 43, 61, 0.78);
  }
  .json-style h5 {
    color: var(--chatlook-darkblue--);
    font-weight: 700;
    font-style: 18px;
  }
}
