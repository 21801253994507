
.progress {
	background-color: #5195d8ed;
	border-radius: 2px;
	position: relative;
	margin: 5px 0;
	height: 17px;
	width: 160px;
}

.progress span{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);

}
.progress-done {
    text-align: center;
	background: #1976d2;
	border-radius: 2px;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 0;
	opacity: 0;
	transition: 1s ease 0.3s;
}