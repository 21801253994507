body {
  // font-family: "Public Sans", sans-serif;
  font-family: "Roboto", sans-serif;
  background-color: #f8f7fa !important;
  color: #000000 !important;
  // font-family: 'Mulish', sans-serif;
}
:root {
  --ev-white--: #fff;
  --ev-gray--: #c2c2c2;
  --ev-lightblue--: #8b89a9;
  --ev-darkblue--: #090447;
  --ev-green--: #16b239;
  --ev-yellow--: #fff27d;
  --chatlook-white--: #fff;
  --chatlook-lightblue--: #ededfc;
  --chatlook-darkblue--: #6f6af8;
  --chatlook-green--: #15cd2b;
  --chatlook-yellow--: #fff27d;
  --chatlook-sky--: #86c1f8;
  --chatlook-grey--: #f2f3f8;
}

a {
  text-decoration: none;
  color: unset;
}

.themecolor {
  color: var(--chatlook-darkblue--) !important;
}
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.flatpicker-main-div {
  border: 1px solid #c9d5e1 !important;
  background: #fff;
  padding: 0 6px 0 6px;
  border-radius: 6px;
}
.flatpicker-main-div svg {
  width: 18px;
}

.card {
  margin-bottom: 12px !important;
  background-color: #ffffff;
  box-shadow: 0 4px 18px rgba(47, 43, 61, 0.1), 0 0 transparent, 0 0 transparent;
}

.card.zinde {
  z-index: 22;
}

.css-1u9des2-indicatorSeparator {
  display: none !important;
}

.table-responsive {
  // border-radius: 10px !important;
  table {
    border-radius: 10px !important;
    border: 1px solid #dedde0 !important;

    thead {
      tr {
        th {
          padding: 10px !important;
          background-color: #f6f6f7 !important;
        }
      }
    }

    tbody {
      vertical-align: baseline;

      // border: 1px solid #dedde0;

      tr {
        border-bottom: 1px solid #d0d2d6 !important;

        td {
          padding: 10px;
          background-color: #fff !important;

          // .d-flex {
          //   .cell-order {
          // .order-singal-div {
          //   display: flex;
          //   align-items: center !important;
          // }
          //   }
          // }
        }
      }
    }
  }
}
tr.lasttablerow {
  border-radius: 0;
  background-color: #979797;
  td {
    color: #fff !important;
  }
}

.css-b62m3t-container {
  margin-right: 10px;
}

.bootstrap-table-column {
  min-height: 48px;
  height: 48px;
}

.select-div {
  // margin-bottom: 10px;
  .form-label {
    margin-bottom: 4px;
    font-size: 14px;
    font-weight: 600;
  }

  .css-13cymwt-control {
    border: 1px solid #c9d5e1 !important;
    background: #f8fafc;
  }

  .form-control {
    font-size: 14px;
    border: 1px solid #c9d5e1;
    background: #fff;
  }

  .date {
    border: none;
    box-shadow: none !important;
    color: #6e6b7b;
  }
}

.detail-lable label {
  font-size: 14px;
  margin-top: 10px;
}

.detail-lable h4 {
  margin-bottom: 0;
  font-size: 16px;
  color: var(--chatlook-darkblue--);
  font-weight: 500;
}
.custom-toast {
  display: flex;
  align-items: center;
  background-color: #f8edd7;
  color: #b67900;
  padding: 10px;
  border-radius: 5px;
}

.custom-toast svg {
  margin-right: 10px;
  fill: #f8edd7;
}
button {
  text-wrap: nowrap !important;
}
.btn-primary {
  padding: 8px 12px;
  height: 34px;
  background-color: var(--chatlook-darkblue--) !important;
  border: 1px solid var(--chatlook-darkblue--) !important;
}

.btn-primary:hover {
  color: var(--chatlook-darkblue--) !important;
  background-color: transparent !important;
  border: 1px solid var(--chatlook-darkblue--) !important;
}
.btn-primary:focus {
  color: #fff !important;
  background-color: var(--chatlook-darkblue--) !important;
  border: 1px solid var(--chatlook-darkblue--) !important;
}

.btn-danger {
  padding: 8px 12px;
  height: 35px;
}

.btn-danger:hover {
  border-color: 1px solid #ea5455 !important;
  color: #ea5455 !important;
  background-color: transparent !important;
  box-shadow: none !important;
}
.btn-dangerhover {
  border-color: 1px solid var(--chatlook-darkblue--) !important;
  color: #ea5455 !important;
  background-color: transparent !important;
  box-shadow: none !important;
}
.btn-dangerNohover {
  border: 1px solid #ea5455 !important;
  background-color: #ea5455 !important;
  box-shadow: none !important;
}

.btn-dangerNohover:focus {
  border: 1px solid #ea5455 !important;
  background-color: #ea5455 !important;
  box-shadow: none !important;
}

.btn-smallsize1 {
  font-size: 10px;
  padding: 4px !important;
  height: 18px !important;
}

.btn-success {
  padding: 8px 12px;
}
.btn-success:hover {
  border-color: 1px solid #28c76f !important;
  color: #28c76f !important;
  background-color: transparent !important;
}

.btn-smallsize {
  font-size: 12px;
  padding: 6px !important;
  height: 28px !important;
}

.form-select {
  border: 1px solid #c9d5e1 !important;
  background-color: #f8fafc;
  min-width: 73px !important;
}

li.sidebar-item {
  cursor: pointer;
}

.css-t3ipsp-control {
  box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1);
  border: 1px solid #c9d5e1;
}

.css-t3ipsp-control:focus {
  box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1);
  background: #f8fafc !important;
  border: 1px solid #c9d5e1;
}

.css-t3ipsp-control:hover {
  box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1);
  background: #f8fafc !important;
  border: 1px solid #c9d5e1;
}

.dollar-icon {
  margin-right: 10px;
}

.success-color {
  color: #28c76f;
  background-color: #28c76f4d;
  padding: 5px;
  border-radius: 8px;
}

.fail-color {
  color: #ff0000f1;
  background-color: #ff000069;
  padding: 5px;
  border-radius: 8px;
}

.filterbtn {
  padding: 8px 12px;
  height: 35px;
  background-color: #fff !important;
  color: var(--chatlook-darkblue--) !important;
  border: 1px solid var(--chatlook-darkblue--) !important;
}

.filterbtn:hover {
  background-color: var(--chatlook-darkblue--) !important;
  color: #fff !important;
  border: 1px solid var(--chatlook-darkblue--) !important;
}
.submitbutton {
  padding: 6px 12px !important;
  height: 32px;
  font-size: 14px;
  background-color: var(--chatlook-darkblue--) !important;
  color: #fff !important;
  border: 1px solid var(--chatlook-darkblue--) !important;
}
.submitbutton:hover {
  background-color: var(--chatlook-darkblue--) !important;
  color: #fff !important;
  border: 1px solid var(--chatlook-darkblue--) !important;
}
.submitTransbutton {
  padding: 6px 12px !important;
  height: 32px;
  font-size: 14px;
  background-color: #fff !important;
  color: var(--chatlook-darkblue--) !important;
  border: 1px solid var(--chatlook-darkblue--) !important;
}
.submitTransbutton:focus {
  padding: 6px 12px !important;
  height: 32px;
  font-size: 14px;
  background-color: #fff !important;
  color: var(--chatlook-darkblue--) !important;
  border: 1px solid var(--chatlook-darkblue--) !important;
}
.submitTransbutton:hover {
  background-color: var(--chatlook-darkblue--) !important;
  color: #fff !important;
  border: 1px solid var(--chatlook-darkblue--) !important;
}
.graybutton {
  padding: 6px 12px !important;
  height: 32px;
  font-size: 14px;
  background-color: #fff !important;
  color: var(--ev-gray--) !important;
  border: 1px solid var(--ev-gray--) !important;
}
.graybutton:hover {
  background-color: #fff !important;
  color: var(--chatlook-darkblue--) !important;
  border: 1px solid var(--chatlook-darkblue--) !important;
}

.filterbtn.update-btn {
  padding: 6px 12px;
  font-size: 13px;
}

.filterbtn:focus {
  background-color: #fff !important;
  color: var(--chatlook-darkblue--) !important;
  border: 1px solid var(--chatlook-darkblue--) !important;
}

h4.main-title {
  margin-bottom: 0;
  color: var(--chatlook-darkblue--);
}

.model-scroll {
  height: 90vh !important;
}

.model-scroll {
  overflow-y: scroll !important;
}

.purpole-color {
  color: var(--chatlook-darkblue--);
}

.led-box {
  height: 30px;
  margin: 10px 0;
  position: absolute;
  left: -30px;
  top: -6px;
}

.led-red {
  margin: 0 auto;
  width: 24px;
  height: 24px;
  background-color: #f00;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px,
    rgba(255, 0, 0, 0.5) 0 2px 12px;
  -webkit-animation: blinkRed 0.5s infinite;
  -moz-animation: blinkRed 0.5s infinite;
  -ms-animation: blinkRed 0.5s infinite;
  -o-animation: blinkRed 0.5s infinite;
  animation: blinkRed 0.5s infinite;
}

.led-warn {
  margin: 0 auto;
  width: 24px;
  height: 24px;
  background-color: #fff200;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #bd8e03 0 -1px 9px,
    rgba(255, 217, 66, 0.5) 0 2px 12px;
  -webkit-animation: blinkWarn 0.5s infinite;
  -moz-animation: blinkWarn 0.5s infinite;
  -ms-animation: blinkWarn 0.5s infinite;
  -o-animation: blinkWarn 0.5s infinite;
  animation: blinkWarn 0.5s infinite;
}

.for-bottombtn {
  padding: 30px 0 15px;
  background-color: #f8f8f8;
  position: sticky;
  bottom: 0;
  right: 0;
  left: 0;
}

li.bn-tran-table {
  cursor: pointer;
}

li.bn-tran-table span:hover {
  color: var(--chatlook-darkblue--);
}

h5.tab-table-heading {
  margin-bottom: 0;
  color: #5e5873;
  font-weight: 600;
}

button.nav-menubtn {
  padding: 5px;
}

.sticky-navbar {
  backdrop-filter: blur(5px);
  z-index: 10;
  top: 0px;
  position: sticky;
}

.badge-danger {
  color: #fff;
  background-color: #ff3366;
}

.badge-success {
  color: #fff;
  background-color: #10b759;
}

.badge-warning {
  color: #212529;
  background-color: #fbbc06;
}

.badge-info {
  color: #212529;
  background-color: #66d1d1;
}

.badge-outlineprimary {
  color: #727cf5;
  border: 1px solid #727cf5;
}

.badge-outlineinfo {
  color: #66d1d1;
  border: 1px solid #66d1d1;
}

.badge-danger-muted {
  color: #212529;
  background-color: #f77eb9;
}

.badge {
  border-radius: 0.25rem;
  color: #fff;
  display: inline-block;
  font-size: 0.8em;
  font-weight: 500;
  line-height: 1;
  padding: 0.35em 0.65em;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;
}

.tras-sumheight {
  max-height: 100%;
  height: 100%;
}

.rotate-animation {
  animation: rotate 2s linear infinite;
  transform-origin: center;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.aurorefresh-btn {
  position: relative;
}

.aurorebtn-txt {
  opacity: 0;
  top: 35px;
  position: absolute;
  left: -14px;
}

.aurorefresh-btn:hover .aurorebtn-txt {
  opacity: 1;
  transition: 0.5s ease-in;
}

.css-1nmdiq5-menu {
  z-index: 2;
}

button.btn-login {
  border-radius: 8px;
  border: var(--chatlook-darkblue--) !important;
  background-color: var(--chatlook-darkblue--) !important;
}

::-webkit-scrollbar {
  width: 6px;
  height: 0px;
  border-radius: 50%;
}

/* Track */
/* Handle */
::-webkit-scrollbar-thumb {
  // background: rgb(231, 231, 231);
  background: gray;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #8b89a9;
  // background: gray;
}

.main::-webkit-scrollbar {
  width: 4px;
}

.status-btn-div {
  // height: 25px;
  // width: 25px;
  // border-radius: 50%;
  border-radius: 4px;
  font-size: 12px;
  padding: 4px 5px;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  background-blend-mode: color-burn;
  opacity: 5 !important;
  // max-width: 125px;
}

.link {
  color: blue;
  text-decoration: underline;
}

.modal-content {
  position: relative !important;
}

.MuiList-padding {
  padding: 0;
}

.dollar-icon-main p {
  white-space: nowrap;
  font-size: 14px;
}

.dollar-icon-main h6 {
  margin-bottom: 0 !important;
  white-space: nowrap;
}
.card-margin {
  margin-bottom: 0 !important;
  height: 100%;
  max-height: 100%;
}
.bg-success {
  color: white;
  background-color: #3c9822 !important;
}

.bg-failed {
  color: white;
  // color: rgb(223, 16, 16) !important;
  background-color: #ea0c0c !important;
}

.bg-initialized {
  color: white;
  // color: rgb(241, 124, 14) !important;
  background-color: #debd1d !important;
}

.bg-processing {
  color: white;
  // color: rgb(188, 197, 94) !important;
  background-color: #ff6600 !important;
}

.bg-expired {
  color: white;
  // color: rgb(224, 76, 17) !important;
  background-color: #ff5454 !important;
}

.bg-not-attempted {
  color: white;
  // color: rgb(96, 48, 184) !important;
  background-color: #08c49b !important;
}

.bg-notFound {
  color: white;
  // color: rgb(96, 48, 184) !important;
  background-color: #a19d9d !important;
}

.bg-pending {
  color: white;
  // color: rgb(197, 47, 235) !important;
  background-color: #175bc1 !important;
}
.bg-recheck {
  color: white;
  // color: rgb(197, 47, 235) !important;
  background-color: #009d9a !important;
}
.bg-open {
  color: white;
  background-color: #ff3aca !important;
}
.bg-lowbal {
  color: white;
  background-color: #4d3d91 !important;
}
.bg-frozen {
  color: white;
  background-color: #3e7696 !important;
}
.bg-textsuccess {
  color: #3c9822 !important;
}
.bg-textfailed {
  color: #ea0c0c !important;
}
.bg-textinitialized {
  color: #debd1d !important;
}
.bg-textprocessing {
  color: #ff6600 !important;
}
.bg-textexpired {
  color: #ff5454 !important;
}
.bg-textpending {
  color: #175bc1 !important;
}
.bg-textopen {
  color: #ff3aca !important;
}
.bg-textrecheck {
  color: #009d9a !important;
}
.bg-textlowbal {
  color: #4d3d91 !important;
}
.bg-textfrozen {
  color: #3e7696 !important;
}
.bg-textnot-attempted {
  color: #08c49b !important;
}

@media screen and (max-wigth), (-ms-high-contrast: none) {
  // img-fluid class
  .card-body,
  .card-content {
    min-height: 1px;
  }
}

.cols-4 {
  width: 100%;
}

.table-filterbtn {
  height: 35px;
  width: 35px;
  // background-color: rgba(115, 103, 240, 0.16) !important;
  border: 1px solid var(--chatlook-darkblue--) !important;
  color: var(--chatlook-darkblue--);
  border-radius: 4px;

  svg {
    color: var(--chatlook-darkblue--);
  }
}

.inputgap {
  gap: 12px;
}

// Drop Down CSS
.filter-search {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
  width: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.online {
  background-color: rgb(40, 199, 111);
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.offline {
  background-color: #ff3366;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.center {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  display: flex;
  // align-items: center;
  // justify-content: center;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ver-center {
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translate(1rem, -50%);
}

.cell-order {
  .order-singal-div {
    display: flex;
    p,
    div {
      color: #000;
      margin-bottom: 0 !important;
      font-weight: 500;
      font-size: 14px;
    }
    span {
      color: rgb(47 43 61);
      font-size: 14px;
      font-weight: 400;
    }
  }
  .badge {
    padding: 6px 5px 3px;
  }
  .client-singal-div {
    span {
      max-width: 190px;
      font-size: 12px;
    }
  }
  .date-single-div {
    min-width: 132px;
    p {
      color: black;
      font-weight: 700;
    }
  }
}
.cell-order-white {
  p {
    margin: 0 !important;
  }
  .order-singal-div {
    display: flex;
    p {
      color: #000000cb;
      font-weight: 400;
      font-size: 14px;
    }
    span {
      color: rgba(47, 43, 61, 0.78);
      // font-size: 14px;
      // font-weight: 700;
      // color: #000000bf;
      font-size: 14px;
      font-weight: 500;
    }
  }
  .client-singal-div {
    span {
      max-width: 190px;
      font-size: 12px;
    }
  }
  .date-single-div {
    min-width: 132px;
    p {
      color: black;
      font-weight: 700;
    }
  }
}
.dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  border: 1px solid rgba(47, 43, 61, 0.6784313725);
}
// .cell-order-white {
//   p {
//     margin: 0 !important;
//   }
//   .order-singal-div {
//     display: flex;
//     p {
//       color: #fff;
//       font-weight: 400;
//       font-size: 14px;
//     }
//     span {
//       color: #fff;
//       // font-size: 14px;
//       // font-weight: 700;
//       // color: #000000bf;
//       font-size: 14px;
//       font-weight: 500;
//     }
//   }
//   .client-singal-div {
//     span {
//       max-width: 190px;
//       font-size: 12px;
//     }
//   }
//   .date-single-div {
//     min-width: 132px;
//     p {
//       color: black;
//       font-weight: 700;
//     }
//   }
// }

.wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tableinput {
  gap: 6px;
}
.tableinput input.form-control {
  padding: 3px 6px;
}
.form-check-input:checked {
  background-color: var(--chatlook-darkblue--) !important;
  border-color: var(--chatlook-darkblue--) !important;
  box-shadow: unset !important;
}

.checkboxtxt label {
  font-size: 16px;
}
.uplodstyle label {
  font-size: 15px !important;
}
.chartcss .chartheight {
  height: 400px;
}
.chartcss canvas {
  display: inline !important;
  width: 100% !important;
}

.btncell-height {
  height: 105px;
}

.react-datepicker__header {
  background-color: var(--chatlook-darkblue--) !important;
  border-bottom: 1px solid var(--chatlook-darkblue--) !important;
}
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after {
  border-bottom-color: var(--chatlook-darkblue--) !important;
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
  border-color: #fff !important;
}
.react-datepicker__navigation-icon {
  top: 2px !important;
}
.react-datepicker__current-month {
  color: #fff !important;
  font-size: 14px !important;
}
.react-datepicker__day-name {
  color: #fff !important;
}

.react-datepicker__day--in-range {
  color: #000 !important;
  background-color: rgb(187 181 255 / 38%) !important;
}
.react-datepicker__day--keyboard-selected {
  color: #000 !important;
  background-color: rgb(187 181 255 / 38%) !important;
}
.react-datepicker__day--in-range:hover {
  // color: #fff !important;
  background-color: rgb(187 181 255 / 38%) !important;
}
.react-datepicker__day--in-selecting-range:not(.react-datepicker_day--in-range),
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range {
  color: #000 !important;
  background-color: rgb(187 181 255 / 38%) !important;
}
// .react-datepicker__day--in-range {
//   color: #000 !important;
// }

.button-space {
  gap: 10px;
}
.modaltable {
  display: flex;
  justify-content: space-around;
}
.modaltable span {
  padding: 6px;
  text-align: center;
  width: 100%;
  border: 1px solid rgba(185, 172, 172, 0.432);
  border-right: 0;
}

.form-control {
  padding: 6.35px 12px;
}
.w-20 {
  width: 20% !important;
}
.withcss {
  display: flex;
  // grid-template-columns: auto auto auto auto auto;
  flex-wrap: wrap;
}
.withcssupi {
  display: flex;
}
.withdashalign {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
}

.pgsec {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  color: rgba(47, 43, 61, 0.6784313725);
}
.pgsec span {
  font-size: 15px;
}
.pgsec i {
  font-size: 16px;
}
.pgsec img {
  width: 18px;
}

.profilesec {
  .order-singal-div {
    color: rgba(47, 43, 61, 0.68);
    h5 {
      font-size: 14px;
      margin-bottom: 0px;
    }
    p {
      font-size: 12px;
      margin-bottom: 0;
    }
  }
}

.subpadding li {
  padding: 10px 25px !important;
}
.logoutsec {
  padding: 16px 8px;
}
.logoutsec a {
  font-size: 14px;
}
.logoutsec img {
  width: 20px;
}
.logout-position {
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translate(0rem, -50%);
}
.merchnt-drop {
  width: fit-content;
  padding: 10px;
}
.btn.avtrabtn:first-child:active {
  border-color: transparent;
}
.allbank {
  display: flex;
  align-items: center;
  gap: 10px;
}

.max-h-modal {
  max-height: 90vh;
  overflow: scroll;
  position: relative;
}
.less-h-modal {
  max-height: 70vh;
}

.modal-header {
  position: sticky;
  top: 0;
  z-index: 9999;
}
.modal-header h3 {
  font-size: 18px !important;
  color: #5e5873;
}
.modal-header i {
  cursor: pointer;
  font-size: 20px;
  color: #9e98b0;
}
.modal-footer {
  position: sticky;
  bottom: 0;
  z-index: 99;
  background-color: white;
  padding: 6px 1.4rem !important;
}

.gap {
  display: flex;
  gap: 5px;
}

.bold {
  font-weight: bold;
}

.w-700 {
  font-weight: 500;
}

.w-100 {
  width: 100%;
}

.page-main {
  padding: 0 14px;
  overflow-y: scroll;
}
img.subitem-icon {
  width: 20px !important;
}
.hori-logo {
  width: 120px;
}
.MuiPaper-root {
  padding: 8px 8px !important;
}
.MuiPaper-root a {
  width: 100%;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.css-1nt5alb-MuiListItem-root {
  margin-left: 0px !important;
}

// .css-13b0qiy-MuiListItem-root {
//   margin-left: 0px !important;
// }

.header-border {
  border-bottom: 1px solid lightgray;
  border-radius: 0 !important;
  padding: 0 !important;
}

#page-topbar {
  height: 60px !important;
}

.MuiPaper-root {
  padding: 0 !important;
}
.MuiListItem-root {
  margin-bottom: 0 !important;
  margin-left: 0px !important;
}
.css-1uam3ae-MuiToolbar-root {
  padding: 0 !important;
}
.css-xupl7-MuiListItem-root {
  margin: 0 !important;
}
.css-1imh1kn {
  margin: 0 !important;
}

.navbar-content-container {
  padding: 0 !important;
}
.centralbank {
  margin-bottom: 10px;
}
.centralbank p {
  // font-size: 14px;
  margin-bottom: 0;
}
.modal-width {
  max-width: 600px;
}
img.loaderimg {
  width: 20px;
}
.border-b-1 {
  border-bottom: 1px solid #ebe9f1;
}
.upi-position {
  position: absolute;
  top: 50%;
  transform: translate(0px, -50%);
}
.upi-position span {
  padding: 5px 0;
}
.upi-position p.themecolor {
  padding: 5px 0;
}
.resellertable thead th {
  padding: 6px 10px;
}
.resellertable tbody tr td {
  padding: 6px 10px;
}
.level-main {
  display: grid;
  grid-template-columns: auto auto;
}
.level-main h5 {
  color: #5e5873;
  font-weight: 600;
}
.levels {
  border-right: 1px solid #ebe5e5;
}
.checkbox-icon .form-check-input {
  background-size: 11px;
  width: 15px;
  height: 15px;
}
.text-red {
  color: red !important;
}

.bg-row-lightpink {
  background-color: #f7948da1 !important;
}
.bg-row-yellow {
  background-color: #fbc11ca1 !important;
}
.bg-row-lightyellow {
  background-color: #ffefd5a1 !important;
}
.bg-row-lightpurple {
  background-color: #e0b5ffa1 !important;
}
.colpadding {
  padding-right: 0px;
}

/* media  */

/* 1680 */
@media (max-width: 1700px) {
}

/* 1500 */
@media (max-width: 1680px) {
  .withdashalign {
    display: grid;
    grid-template-columns: auto auto auto auto;
  }
  .chartcss .chartheight {
    height: unset;
  }
}

/* 1440 */
@media (max-width: 1449px) {
  .withdashalign {
    display: grid;
    grid-template-columns: auto auto auto;
  }
}

@media (max-width: 1279px) {
}
@media (max-width: 1117px) {
  .allbank {
    display: flex;
    justify-content: end;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
  }
}
/* 1440 */
@media (max-width: 1090px) {
  .withcssupi {
    display: flex;
    flex-wrap: wrap;
  }
  .withdashalign {
    display: flex;
    flex-wrap: wrap;
  }
}
@media (max-width: 991px) {
  .colpadding {
    padding-right: 1rem;
  }
}

.resmeseg p {
  font-size: 12px !important;
}
.resmeseg span {
  font-size: 12px !important;
}
.resmeseg p {
  width: 280px;
  text-wrap: wrap;
}

.scrollbtn {
  background-color: var(--chatlook-darkblue--);
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.scrollbtn i {
  font-size: 20px;
  color: #fff;
}

.scroll-to-top {
  z-index: 99999999;
  position: fixed;
  bottom: 20px;
  right: 40px;
  cursor: pointer;
}

// .horizontal-layout {
//   display: block !important;
// }

// .vertical-layout {
//   display: none !important;
// }

.trans-imgwidth {
  text-align: center;
}
.trans-imgwidth img {
  width: 280px;
}
.css-12408xj {
  padding-top: 18px !important;
}
.css-13265al {
  padding-left: 25px !important;
}
.flexfill {
  flex: 1 !important;
}
// @media screen and (max-width: 768px) {
//   .horizontal-layout {
//     display: none !important;
//   }
//   .vertical-layout {
//     display: block !important;
//   }
// }

.bankparse-file .proof-sec .label {
  padding: 5px;
  font-size: 12px;
}
.disabled {
  pointer-events: none !important;
}
.timezone-headerlogo img {
  height: 40px !important;
}

.text-warning {
  color: var(--ev-yellow--);
}

.border-dashed {
  border-style: dashed;
}

.border-solid {
  border-style: solid;
}

.border-dotted {
  border-style: dotted;
}
.adblock-box {
  position: fixed;
  top: 10px;
  right: 10px;
}
.adblock-box h4 {
  margin-bottom: 0;
  padding: 4px 10px;
  border-radius: 4px;
  font-size: 16px;
  background-color: #f8edd7;
  color: #b67900;
}

.webevent-sec {
  background-color: rgb(83 123 255 / 16%);
  // background-color: #80808029;
  padding: 20px;
  border-radius: 10px;
}
.webevent-sec pre {
  margin-bottom: 0;
  background-color: transparent;
  font-size: 14px;
  font-weight: 500;
  color: rgba(47, 43, 61, 0.78);
}
.req_text_wrap pre {
  text-wrap: wrap !important;
}
.json-style h5 {
  color: var(--chatlook-darkblue--);
  font-weight: 700;
  font-style: 18px;
}

.googleCode {
  max-width: 900px;
  display: inline-block;
  background: none;
  white-space: pre;
}

.whitespace-webevent {
  white-space: normal !important;
}
.active_row {
  background-color: rgb(86 86 86 / 18%) !important;
}
.table tr.tablecell-hoverx.active_row:hover {
  background-color: rgb(86 86 86 / 18%) !important;
}
.rolesub_sec{
  padding: 10px 0;
  border-bottom: 1px solid #ebe5e5;
}
.roles_name{  
  border-right: 1px solid #ebe5e5;
}
.checkbox_margin{
  margin-top: 6px;
}
.addrole_header{
  position: sticky;
  right: 0;
  left: 0;
  top: 0px;
}
// .addRole-main{
// height: 550px ;
// overflow-y: scroll;
// }
.addrole-footer{
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: end;
}

.addpg_input{
  height: 40px;
}