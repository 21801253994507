.login-wrapper {
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  .LoginOne {
    position: absolute;
    top: 0;
    left: 0;
  }
  .LoginTwo {
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .LoginThree {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .login-inner {
    padding: 35px;
    width: 548px;
    border-radius: 24px;
    background: #fff;
    z-index: 9999;
    border:2px solid gray;

    .loghead h2 {
      margin-top: 12px;
      margin-bottom: 12px;
      color: #141212;
      font-size: 20px;
      font-weight: 700;
    }
    .loghead p {
      margin-bottom: 30px;
      color: #8f93ae;
      font-size: 16px;
      font-weight: 400;
    }
    .auth-login-form {
      .form-label {
        color: #8f93ae;
        font-size: 14px;
        font-weight: 500;
      }
      .form-control {
        color: #8f93ae;
        border-radius: 8px;
        border: 1px solid #dee1f4;
        background: #fff;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  .login-wrapper {
    .LoginOne {
      display: none;
    }
    .LoginTwo {
      display: none;
    }
    .LoginThree {
      display: none;
    }
    .login-inner {
      margin: 10px;
      padding: 20px;
      width: 100%;

      .loghead h2 {
        font-size: 22px;
      }
    }
  }
}
