.block-title {
  color: #6c757e;
  font-size: 14px;
  margin-bottom: 10px;
}
.block-data {
  color: #1c1c1c;
  font-size: 16px;
  font-weight: 400;
}

.modal-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
    .block-data {
      max-width: 200px;
      word-wrap: break-word;
    }
  }
  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
    .block-data {
      max-width: unset;
    }
  }
}
