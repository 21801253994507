#page-topbar {
  height: 75px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  transition: margin 0.5s;
  z-index: 99;

  .navbar-header {
    transition: margin 0.5s ease 0s;
    @media (max-width: 768px) {
      margin-left: 0;
    }

    .vertical-menu-btn {
      transition: margin 0.5s;
      border-color: transparent !important;
    }
    .dev-div {
      border: 1px solid #f1f1f1;
      border-radius: 50%;
      padding: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      width: 40px;
    }
    #page-header-user-dropdown {
      border: transparent !important;
    }
    .dropdown-menu {
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
      color: black !important;
      font-weight: 500;
      li {
        justify-content: space-between;
        display: flex;
        padding-right: 25px !important;
        padding-left: 25px !important;
      }
    }
  }
}

.header-sreach {
  display: flex;
  align-items: center;
}
.header-sreach input {
  border: 0;
  padding: 8px 12px;
}
.header-sreach input.form-control:focus {
  box-shadow: none;
}
.header-maininput {
  margin-left: 10px;
  display: flex;
  align-items: center;
  border: 1px solid rgba(47, 43, 61, 0.16);
  border-radius: 10px;
  padding: 0 10px;
}
.header-padding {
  padding: 0 !important;
}
