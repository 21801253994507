.merchant-main-div {
  .primary {
    background: var(--chatlook-darkblue--) !important;
    border-color: transparent !important;
  }
  .primary-btn {
    background: rgba(120, 234, 131, 0.6) !important;
    color: #16a928 !important;
    border-radius: 50%;
    width: 40px !important;
    height: 40px !important;
    border: none;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .primary-warning {
    background: #ff928199 !important;
    color: #e6533c !important;
    border-radius: 50%;
    width: 40px !important;
    height: 40px !important;
    border: none;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  table {
    tbody {
      .row {
        padding-top: 7px;
        padding-bottom: 7px;
        div:first-child {
          color: black;
          font-weight: 800;
        }
        .right-part {
          div {
            color: gray !important;
          }
        }
      }
    }
  }
  .merchant-management-card {
    .row {
      align-items: center;
      display: flex;
      .card {
        margin-bottom: 10px !important ;
        .card-body {
          border-radius: 8px;
          background-color: white;
          box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
        }
      }
    }
  }
  @media (max-width: 400px) {
    .front-filter-div {
      flex-wrap: wrap;
      select {
        max-width: 196px;
      }
      input {
        margin-top: 10px;
      }
    }
  }
  .select {
    width: 170px;
  }
}
.input-fil {
  display: flex;
  align-items: center;
  margin: 5px;
}
.check-box {
  margin-left: 10px;
  display: flex;
}
.check-box button {
  margin-right: 10px;
  height: 34px;
}
.yes-no {
  display: flex;
}
.autometa-sec {
  padding-right: 0;
}
.fourbox-detail {
  display: flex;
  gap: 12px;
  padding-bottom: 12px;
}
.detailboxMain {
  width: 100%;
}
// .detailsec-scroll {
//   overflow-y: scroll;
// }
// .detailboxFix {
//   width: 400px !important;
// }

.manualpadding {
  padding: 0 12px;
}

@media screen and (max-width: 1300px) {
  .detailsec-scroll {
    display: flex !important;
  }
  .detailboxMain {
    width: unset;
  }
  .detailsec-scroll {
    overflow-y: scroll !important;
  }
  .detailboxFix {
    width: 300px !important;
  }
}

@media screen and (max-width: 991px) {
  .autometa-sec {
    padding-right: 16px;
  }
  .manualpadding {
    padding: 0 16px;
  }
  .fourbox-detail {
    display: grid;
    grid-template-columns: auto auto;
  }
}

// .yesno-btn {
//   label {
//     user-select: none;
//     display: flex;
//     align-items: center;
//     position: relative;
//     width: max-content;
//     cursor: pointer;
//     input {
//       appearance: none;
//       transition-property: color, background-color, border-color,
//         text-decoration-color, fill, stroke;
//       transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
//       transition-duration: 150ms;
//       cursor: pointer;
//       width: 3.5rem;
//       height: 1.75rem;
//       border-radius: 50%;
//     }
//     input:focus {
//       outline: none;
//       box-shadow: var(--tw-ring-inset) 0 0 0
//         calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
//       --tw-ring-offset-width: 2px;
//       box-shadow: 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color),
//         var(--tw-ring-shadow);
//       --tw-ring-offset-color: #000;
//       box-shadow: 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color),
//         var(--tw-ring-shadow);
//       --tw-ring-color: rgb(59 130 246);
//       background-color: rgb(239 68 68);
//     }
//     span {
//       position: absolute;
//       .offbtn {
//         color: #fff;
//         right: 4px;
//       }
//       .onbtn {
//         color: #fff;
//         right: 2rem;
//       }
//       .allbtn {
//         width: 1.75rem;
//         height: 1.75rem;
//         right: 28px;
//         transition-property: transform;
//         transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
//         transition-duration: 150ms;
//         background-color: rgb(229 231 235);
//       }
//     }
//   }
// }

input:checked {
  background-color: #22c55e; /* bg-green-500 */
}

input:checked ~ span:last-child {
  --tw-translate-x: 1.75rem; /* translate-x-7 */
}

.loader-div {
  position: absolute;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: gray;
  opacity: 0.3;
  color: white;
}
