.payout-main-div {
  .primary {
    background: var(--chatlook-darkblue--) !important;
    border-color: transparent !important;
  }

  .merchant-management-card {
    .row {
      align-items: center;
      display: flex;
      .card {
        margin-bottom: 10px !important ;
        .card-body {
          border-radius: 8px;
          background-color: white;
          box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
        }
      }
    }
  }

  @media (max-width: 400px) {
    .front-filter-div {
      flex-wrap: wrap;
      select {
        max-width: 196px;
      }
      input {
        margin-top: 10px;
      }
    }
  }
}
