.react-date-picker {
  .datepicker {
    border: none;
    height: 100%;
    outline: none;
    padding: 8px;
    font-size: 14px;
  }

  .react-datepicker-popper {
    z-index: 999;
  }
  font-size: 14px;
}
