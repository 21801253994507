.table {
  th.heading-color {
    position: relative;
    text-align: left;
  }
  tr.tablecell-hoverx:hover {
    background-color: rgb(246, 246, 247) !important;
  }

  th.heading-color::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    height: 25px;
    width: 1px;
    background-color: #8b89a92d;
  }
  th.heading-color:last-child::after {
    display: none;
  }
  .open-padding {
    border-top: 1px solid #ebe9f1;
    padding: 10px 1rem;
    background-color: transparent;
  }
  td.open-padding {
    vertical-align: top !important;
    font-size: 14px;
    font-weight: 400 !important;
    color: #000;
  }
}

// .table-row {
//   transition: 1s ease-in-out;
//   transform-origin: center;
//   // border-bottom: 1px;
// }
.main-table {
  // position: relative;
  overflow-y: scroll;
}
.table th.heading-color {
  color:#000000;
  font-weight: 500;
  font-size: 14px;
  padding: 8px 1rem;
  background-color: rgb(246, 246, 247) !important;
}

.no-wrap {
  white-space: nowrap;
}

.table-loader {
  position: absolute;
  width: 100%;
  overflow: hidden;
  top: 50%;
  transform: translate(0, -50%);
  right: 0;
  left: 0;
}

.table-cell {
  background-color: #fff;
}

.text-wrap {
  text-wrap: wrap;
  max-width: 300px;
}
.table-pagination {
  width: 100%;
  overflow: hidden;
  margin-bottom: 18px;
  margin-right: 10px;
  background-color: #fff;
  .table-pagi-overflow {
    left: 0;
    background-color: #fff;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 10px;
  }

  .MuiToolbar-root {
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      margin: 0;
    }
  }
}

.main-table::-webkit-scrollbar {
  width: 3px;
  height: 5px;
}
.main-table::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.main-table::-webkit-scrollbar-thumb {
  background-color: lightgray;
}
.main-table::-webkit-scrollbar-thumb:hover {
  background-color: lightgray;
}
.tablecell-hover:hover {
  background-color: rgb(246, 246, 247) !important;
}
.table-header {
  position: sticky;
  top: 0;
  // z-index: 1;
}

.css-jsexje-MuiSwitch-thumb {
  background-color: var(--chatlook-darkblue--)  !important;
}
.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked
  + .MuiSwitch-track {
  background-color: rgba(114, 103, 240, 0.582) !important;
}
.css-1yjjitx-MuiSwitch-track {
  background-color: #00000096;
}

.open-tabledata {
  td {
    padding: 10px 0;
  }
  border-bottom: 1px solid rgba(0, 0, 0, 0.1098039216);
  .detailcell {
    padding: 15px;
    // box-shadow: inset 0 10px 10px -10px #9d9ca7;
    box-shadow: inset 0px 11px 8px -10px #ccc, inset 0px 0px 0px 0px #ccc,
      inset 0px 0px 0px 0px #ccc, inset 0px 0px 0px 0px #ccc;
    background-color: #fff;
  }
  .collapse-sec {
    .tabmain {
      gap: 15px;
      span {
        color: #000;
        padding: 8px 10px 3px;
        font-size: 14px;
        font-weight: 500;
        border-radius: 8px 8px 0 0;
      }
      span.active {
        background-color: #fff;
        color: var(--chatlook-darkblue--);
        border-top: 1px solid var(--chatlook-darkblue--);
        // border-left: 1px solid var(--chatlook-darkblue--);
        // border-right: 1px solid var(--chatlook-darkblue--);
        // background: rgba(115, 103, 240, 0.16);
      }
    }
    .mainbox {
      .box-style {
        width: 25%;
        h5 {
          margin-bottom: 14px;
          span {
            padding-bottom: 5px;
            // border-radius: 4px;
            border-bottom: 1px solid #a7a7a7;
            color: #7d7d7d;
            font-size: 14px;
            font-weight: 500;
          }
        }
        .other-sec {
          b {
            // color: #000;

            color: rgba(47, 43, 61, 0.78);
            font-size: 14px;
            font-weight: 500;
          }
          p {
            margin-bottom: 4px;
            color: #000000cb;
            font-size: 14px;
            font-weight: 500;
            // overflow: hidden;
            // text-overflow: ellipsis;
          }
          // p:hover {
          //   overflow: unset;
          //   text-overflow: unset;
          // }
        }
      }
      .amount-sec {
        margin-top: 8px;
        border-radius: 5px;
        border: 1px solid #c5c3c3;
        // box-shadow: rgba(47, 43, 61, 0.1) 0px 4px 18px 0px;
        padding: 10px 18px;
        .amount-sub {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #7d7d7d;
          h4 {
            margin-bottom: 0;
            color: #000000b8;
            font-size: 20px;
            font-weight: 800;
          }
          p {
            margin-bottom: 0px;
            font-size: 14px;
            font-weight: 400;
          }
          h6 {
            color: #000000b8;
            font-weight: 800 !important;
            margin-bottom: 0px;
            font-size: 14px;
          }
          button.tablebtn {
            color: var(--chatlook-darkblue--) !important;
            background-color: transparent !important;
            border-radius: 4px;
            border: 1px solid var(--chatlook-darkblue--) !important;
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          }
          button.tablebtn:hover {
            background-color: var(--chatlook-darkblue--) !important;
            color: #fff !important;
          }
        }
      }
    }
  }
  // .collapse-sec {
  //   .tabmain {
  //     gap: 15px;
  //     span {
  //       color: #000;
  //       padding: 8px 10px 3px;
  //       font-size: 14px;
  //       font-weight: 500;
  //       border-radius: 8px 8px 0 0;
  //     }
  //     span.active {
  //       background-color: #fff;
  //       color: var(--chatlook-darkblue--);
  //       border-top: 1px solid var(--chatlook-darkblue--);
  //       // border-left: 1px solid var(--chatlook-darkblue--);
  //       // border-right: 1px solid var(--chatlook-darkblue--);
  //       // background: rgba(115, 103, 240, 0.16);
  //     }
  //   }
  //   .mainbox {
  //     padding: 15px 15px;
  //     background-color: #fff;
  //     box-shadow: rgba(47, 43, 61, 0.1) 0px 4px 18px 0px;
  //     border-radius: 0 10px 10px 10px;
  //     .box-style {
  //       width: 25%;
  //       h5 {
  //         margin-bottom: 14px;
  //         span {
  //           padding: 5px 10px;
  //           border-radius: 4px;
  //           background: rgba(115, 103, 240, 0.16);
  //           color: var(--chatlook-darkblue--);
  //           font-size: 14px;
  //           font-weight: 500;
  //         }
  //       }
  //       .other-sec {
  //         b {
  //           color: #4b4c50;
  //           font-size: 14px;
  //           font-weight: 500;
  //         }
  //         p {
  //           margin-bottom: 8px;
  //           color: rgba(47, 43, 61, 0.9);
  //           font-size: 14px;
  //           font-weight: 500;
  //         }
  //       }
  //     }
  //     .amount-sec {
  //       margin-top: 16px;
  //       border-radius: 12px;
  //       border: 1px solid var(--chatlook-darkblue--);
  //       box-shadow: rgba(47, 43, 61, 0.1) 0px 4px 18px 0px;
  //       padding: 10px 18px;
  //       .amount-sub {
  //         display: flex;
  //         justify-content: space-between;
  //         align-items: center;
  //         color: var(--chatlook-darkblue--);
  //         h4 {
  //           margin-bottom: 0;
  //           color: var(--chatlook-darkblue--);
  //           font-size: 20px;
  //           font-weight: 800;
  //         }
  //         p {
  //           margin-bottom: 0px;
  //           font-size: 14px;
  //           font-weight: 400;
  //         }
  //         h6 {
  //           color: var(--chatlook-darkblue--);
  //           font-weight: 700 !important;
  //           margin-bottom: 0px;
  //           font-size: 14px;
  //         }
  //         button.tablebtn {
  //           color: var(--chatlook-darkblue--) !important;
  //           background-color: transparent !important;
  //           border-radius: 4px;
  //           border: 1px solid var(--chatlook-darkblue--) !important;
  //           box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  //         }
  //         button.tablebtn:hover {
  //           background-color: var(--chatlook-darkblue--) !important;
  //           color: #fff !important;
  //         }
  //       }
  //     }
  //   }
  // }
}

.activerow td {
  background-color: rgb(246, 246, 247) !important;
}
.activerow.table-row {
  box-shadow: rgba(47, 43, 61, 0.1) 0px 4px 18px 0px;
}

.red-light,
.green-light,
.yellow-light {
  display: inline-block;
  width: 18px;
  height: 18px;
  background-color: transparent;
  border-radius: 50%;
}



.red-light {
  background-color: rgb(234, 84, 85) !important;
  animation: redLightAnimation 2s ease-in-out infinite;
}

.green-light {
  background-color: rgb(40, 199, 111) !important;
  animation: greenLightAnimation 2s ease-in-out infinite;
}

.yellow-light {
  background-color: rgb(237 236 19) !important;
  animation: greenLightAnimation 2s ease-in-out infinite;
}

.mo-orange-light,
.mo-red-light,
.mo-green-light {
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: transparent;
  border-radius: 50%;
}
.mo-orange-light {
  background-color: rgb(255, 159, 67) !important;
  animation: pinkLightAnimation 2s ease-in-out infinite;
}
.mo-red-light {
  background-color: rgb(234, 84, 85) !important;
  animation: redLightAnimation 2s ease-in-out infinite;
}
.mo-green-light {
  background-color: rgb(40, 199, 111) !important;
  animation: greenLightAnimation 2s ease-in-out infinite;
}
.mostatus-orange-light{
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: transparent;
  border-radius: 50%;
  background-color: #ffda00 !important;
}
.mostatus-green-light {
  background-color: rgb(40, 199, 111) !important;
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: transparent;
  border-radius: 50%;
}


@keyframes redLightAnimation {
  0%,
  100% {
    background-color: transparent;
    box-shadow: 0 0 4px 4px transparent;
  }
  50% {
    box-shadow: 0 0 4px 4px rgba(234, 84, 84, 0.301);
  }
}

@keyframes pinkLightAnimation {
  0%,
  100% {
    background-color: transparent;
    box-shadow: 0 0 4px 4px transparent;
  }
  50% {
    box-shadow: 0 0 4px 4px rgba(255, 158, 67, 0.342);
  }
}

@keyframes greenLightAnimation {
  0%,
  100% {
    background-color: transparent;
    box-shadow: 0 0 4px 4px transparent;
  }
  50% {
    box-shadow: 0 0 4px 4px rgba(40, 199, 111, 0.377);
  }
}

