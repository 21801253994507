.webhook_event-main-div {
  .primary {
    background: var(--chatlook-darkblue--) !important;
    border-color: transparent !important;
  }

  @media (max-width: 400px) {
    .front-filter-div {
      flex-wrap: wrap;
      select {
        max-width: 196px;
      }
      input {
        margin-top: 10px;
      }
    }
  }
}
