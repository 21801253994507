.reconciliation-main-div {
  .primary {
    background: var(--chatlook-darkblue--) !important;
    border-color: transparent !important;
  }

  .merchant-management-card {
    .row {
      align-items: center;
      display: flex;
      .card {
        margin-bottom: 10px !important ;
        .card-body {
          border-radius: 8px;
          background-color: white;
          box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
        }
      }
    }
  }

  .webevent-sec {
    background-color: rgba(83, 123, 255, 0.16);
    padding: 20px;
    border-radius: 10px;
    pre {
      background-color: transparent;
    }
  }

  @media (max-width: 550px) {
    .front-filter-div {
      flex-wrap: wrap;
      select {
        max-width: 196px;
      }
      input {
        margin-top: 10px;
      }
    }
  }
  .data-row {
    border-bottom: 1px solid #e9e9e9;
    .left-part {
      color: #4d4d4d;
      font-weight: 400;
      font-size: 14px;
    }
    .right-part {
      color: #233664;
      font-weight: 500;
      font-size: 14px;
    }
  }
}
