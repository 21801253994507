.home-main-div {
  .chart-container {
    width: 100%;
    height: 400px;
  }
  .merchant-management-card {
    .card-header {
      .row {
        align-items: center;
        display: flex;
        .card {
          margin-bottom: 10px !important ;
          .card-body {
            border-radius: 8px;
            background-color: rgb(255, 255, 255);
            box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
          }
        }
      }
    }
  }
  .transaction-summary-main-div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px !important;
    .row {
      .card {
        margin-bottom: 0 !important;
        .card-body {
          border-radius: 8px;
          background-color: white;
          box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
        }
      }
    }
  }
  .payout-summary-div {
    .row {
      .card {
        margin-bottom: 0 !important;
        .card-body {
          border-radius: 8px;
          background-color: white;
          box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
        }
      }
    }
  }
}

.tran-paddleft {
  padding-right: 0px !important;
}
@media only screen and (max-width: 767px) {
  .tran-paddleft {
    margin-bottom: 1rem;
    padding-right: 1rem;
  }
}

.transaction-summary-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}

.withdrawbox {
  display: grid;
  gap: 0 10px;
  grid-template-columns: auto auto auto;
}

.fontstyle {
  h5 {
    color: rgb(47 43 61);
    font-size: 14px;
    font-weight: 400 !important;
  }
  p {
    color: #000000cb;
    font-weight: 500 !important;
  }
}
.greentext {
  p {
    font-size: 17px;
    color: rgb(30 120 0);
    font-weight: 600 !important;
  } 
}

.imgstyle {
  border: 1px solid rgba(47, 43, 61, 0.16);
  padding: 15px;
  border-radius: 15px;
  img {
    width: 45px;
    padding: 8px;
    background-color: #dcf6e8;
    border-radius: 50%;
    min-height: 43px;
    height: 43px;
  }
}
.withdrawimg {
  border: 1px solid rgba(47, 43, 61, 0.16);
  padding: 15px;
  border-radius: 15px;
  img {
    width: 45px;
    padding: 8px;
    background-color: #fce4e4;
    border-radius: 50%;
  }
}
.timelimeimg {
  border: 1px solid rgba(47, 43, 61, 0.16);
  padding: 15px;
  border-radius: 15px;
  img {
    width: 45px;
    padding: 8px;
    background-color: #e8e7fd;
    border-radius: 50%;
  }
}

.headstyle {
  color: rgba(0, 0, 0, 0.911);
  font-weight: 700 !important;
}
