.input-field {
    .icon {
        position: absolute;
        left:10px;
        cursor: pointer;
    }

    .right-icon {
        position: absolute;
        right:10px;
        cursor: pointer;
    }

    .input-icon {
        padding-left:35px;
    }

    .input-right-icon {
        padding-right:35px;
    }
}