.avatar {
  border-radius: 50%;
  background-color: white;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 20px;
}
