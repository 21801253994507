.app {
  &.sidebar-open {
    .sidebar {
      overflow: scroll;
      width: 212px;
      transition: width 0.5s ease;
      padding: 9px;
      padding-top: 10px;
      padding-left: 15px;
      box-shadow: 0 2px 6px rgba(47, 43, 61, 0.14), 0 0 transparent,
        0 0 transparent;
    }
    .main-content {
      margin-left: 260px;
    }
  }
  .sidebar::-webkit-scrollbar {
    width: 2px; /* Width of the scrollbar */
  }
  .sidebar::-webkit-scrollbar-thumb {
    background-color: rgb(219, 218, 222) !important;
    border-radius: 4px; /* Rounded corners for the thumb */
  }

  .main-content {
    flex: 1;
    padding: 12px 12px 25px;
    transition: margin-left 0.5s ease;
  }
  .sidebar-minOpen {
    width: 50px !important;
    overflow: scroll;
    .sidebar-item {
      &:hover {
        background-color: #f1f1f1;
        border-radius: 6px; /* Add your preferred hover color */
      }
      &.active {
        background: linear-gradient(
          72.47deg,
          rgb(115, 103, 240) 22.16%,
          rgba(115, 103, 240, 0.7) 76.47%
        );

        color: white;
        padding: 10px;
        border-radius: 6px;
        font-weight: bold;
        .pe-1 {
          filter: brightness(0) invert(1);
        }
      }
      i {
        font-size: 15px;
        padding-right: 10px;
      }
    }
  }
}
.sidebar {
  width: 0;
  transition: width 0.5s ease;
  height: 100vh;
  position: fixed;
  z-index: 9;
  background-color: #fff;
  color: rgb(47, 43, 61);
  padding-top: 0 !important;
  font-size: 15px !important;
  .main-sidebar {
    position: sticky !important;
    top: 0;
    background: white;
    margin: 0;
    z-index: 99;
    padding: 10px;
  }
  .sidebar-item {
    &:hover {
      background-color: #f1f1f1;
      border-radius: 6px; /* Add your preferred hover color */
    }
    &.active {
      background: linear-gradient(
        72.47deg,
        rgb(115, 103, 240) 22.16%,
        rgba(115, 103, 240, 0.7) 76.47%
      );
      height: 38px;
      align-items: center;
      display: flex;
      box-shadow: 0 2px 6px rgba(115, 103, 240, 0.48);
      color: white;
      padding: 10px;
      border-radius: 6px;
      font-weight: bold;
      .pe-1 {
        filter: brightness(0) invert(1);
      }
      .mini-sidebar {
        filter: brightness(0) invert(1);
      }
    }
    i {
      font-size: 15px;
      padding-right: 10px;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    overflow: hidden;
    .sidebar-head-txt {
      color: #767578;
      font-size: 12px;
    }
    li {
      padding: 10px;
      img.larger-image {
        width: 32px;
      }
      img.smaller-image {
        width: 20px !important;
      }

      a {
        width: 100%;
        text-decoration: none;
        color: #716e7c;
      }
    }
  }

  .toggle-button {
    display: block;
    margin-bottom: 20px;
    background: none;
    border: none;
    color: #716e7c;
    cursor: pointer;
    outline: none;
  }
}

@media (max-width: 768px) {
  .app.sidebar-open {
    .main-content {
      margin-left: 0 !important;
    }
  }
}

.mainactive {
  border-radius: 6px;
  background-color: rgba(47, 43, 61, 0.06);
}
