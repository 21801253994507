.image-picker {
  width: 100%;

  .main {
    border-width: 1px;
    border-radius: 5px;
    width: 100%;
    padding: 10px;
    background-color: #f0f0f0;
  }
  .main-drop {
    border: 2px dashed var(--chatlook-darkblue--);
  }
  .image {
    margin: auto;
    width: 265px;
  }

  .cross-mark {
    position: absolute;
    right: 5px;
    top: 5px;

    i {
      color: var(--chatlook-darkblue--);
    }
  }

  // .full-size {
  //   width: 100vw;
  //   height: 100vh;
  //   z-index: 99999;
  //   background-color: black;
  //   position: absolute;
  //   top: 0px;
  //   bottom: 0px;
  // }

  .drag {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 99999;
    opacity: 0.9;
  }
  .dragbox{  
    padding: 26px 0;
    .drag-text{
    text-align: center;
    padding: 16px 0;
    img{
      margin-bottom: 8px;
    }
  }
  .dragborder{
    border: 1px solid #dcdada;
    width: 100%;
    position: relative;
    margin: 20px 0;
    span{
      background-color: #f0f0f0;
      padding: 10px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
  }
  .dragbutton{
    padding: 16px 0;
    text-align: center;
    span{
      padding: 10px;
      border-radius:6px ;
    background-color:#dcdada;
    }
  }
  }

}
